import React, { useState } from "react";
import flyer from "../images/cccdCareer.png";

/*const Modal = ({ showModal, onCloseModal }) => {
  if (!showModal) return null;*/

export default function Modal() {
  const [modal, setModal] = useState(true);

  const toggleModal = () => {
    setModal(!modal);
  };

  
  return (
    modal && (
    <div className="modal">
    <div onClick={toggleModal} className="overlay"></div>
      <div className="modal-content">
    {/*<span className="close" onClick={toggleModal}>
          &times;
        </span>*/}
        {/* Add your modal content here */}
        <p><img class="flyer" src={flyer} alt="Career Flyer"></img></p>
        <button className="close-modal" onClick={toggleModal}>
              CLOSE
            </button>
      </div>
    </div>  
  )
  
  );
}

//export default Modal;
