import React, { useState, useEffect } from "react";
import Modal from "../components/Modal"; // Import the Modal component
//import React  from "react"
import { Link } from 'gatsby'

import "../styles/careers.css"
import "../styles/modal.css"

//import { Link } from 'gatsby'
// components
import Layout from "../components/Layout";
import staff1 from "../images/staff.jpg"
import staff2 from "../images/staff2.jpg"
const careers = () => {
  
  
  const [showModal, setShowModal] = useState(true); // Set the initial state to true

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
  };


  
  return (
    <Layout  title="Careers" path="/ Careers">
    <title>Co-op Homecare | Careers</title>
    <main id="careers">
        <div className="center-column careers-content">
          <div id="click">
            <h1> <a href="https://forms.gle/xmjrcXBuQYEsLzbd7" rel="noreferrer" target="_blank">CLICK HERE TO JOIN OUR TEAM!</a></h1>
          </div>
          <div className="center-column">
              <h1 id="title">Our Values</h1>
              <p>
                We help caregivers achieve a fulfilling and joyful work experience through a one-of-a-kind home care agency where caregivers own and operate the business. 
                Caregivers at Co-op Home Care work towards their most important scheduling, training, and professional advancement needs through passionate participation and empowered decision making.
              </p>
              <div className="bullets">
                <h2>Achieve a fulfilling and joyful work experience with us! </h2>
                <div>
                  <ul>
                    <li>Flexible scheduling</li>
                    <li>Competitive wages</li>
                    <li>Mileage reimbursement</li>
                    <li>Registration reimbursements</li>
                    <li>Comprehensive training</li>
                    <li>Sense of community and belonging</li>
                    <li>Supportive work environment</li>
                    <button><a href="https://forms.gle/xmjrcXBuQYEsLzbd7" rel="noreferrer" target="_blank">Apply now ></a></button>
                  </ul>
                  <img src={staff1} alt="Staff" id="staff1"></img>
                 
                </div>
              </div>
              <div className="bullets">
                <h2> How can working at a cooperative help you achieve your goals? </h2>
                <div>
                  <ul>
                    <li>Become a co-owner of the company</li>
                    <li>Share profits from the business together</li>
                    <li>Have a say in how the business is run and operated</li>
                    <li>Be part of a movement</li>
                    <button><a href="https://forms.gle/xmjrcXBuQYEsLzbd7" rel="noreferrer" target="_blank">Apply now ></a></button>
                  </ul>
                  <img src={staff2} alt="Staff" id="staff2"></img>  
                </div>
                
              </div>

              <h1 id="careers-contact"> <Link to="/contact">Have any questions about our career opportunities? Contact Us!</Link></h1>
          </div>
        </div>
    <Modal showModal={showModal} onCloseModal={closeModal} />
    </main>
     
    </Layout>
  )
}

export default careers
